import { statusDrawInfo } from "../champions/statusEffects/statusEffects";
import allChampions from "../champions/champions";
import { playerAnimationManager } from "../model/AnimationManager";
import drawShapeWithAnimators from "./drawShapeWithAnimators";
import { drawHealthBar, alignCanvasOnObject, unalignCanvasFromObject } from "./utility/renderUtilities";

function drawChampionWithAnimators(ctx, player) {
  //Retrieve relevant information.
  const championInfo = allChampions[player.champion];
  const animator = playerAnimationManager.getAnimator(player.id);

  //Center & Rotate canvas on player location.
  alignCanvasOnObject(ctx, player);
  //Draw Champion
  for (let shape of championInfo.drawing.shapes) {
    drawShapeWithAnimators(ctx, shape, animator);
  }
  //Draw Status Effects associated to champion.
  if (statusDrawInfo[animator.state]) {
    for (let shape of statusDrawInfo[animator.state].drawing) {
      drawShapeWithAnimators(ctx, shape, animator);
    }
  }
  //Undo centering & rotation from player location.
  unalignCanvasFromObject(ctx, player);
}

export default function renderChampion(ctx, player) {
  drawHealthBar(ctx, player);
  drawChampionWithAnimators(ctx, player);
}
