//ANIMATION CONSTANTS
//////////////////////
////ATTACK
//////1.
let attackWindUpRot = 160;
let windUpDur = 500;
//////2.
let slashDelay = windUpDur
let slashDur = 150;
let slashRotation = 60

let bladeColor = "#4A8276";

const qAnimation = {
  animation: [
    // Wind Up Animation
    {
      name: "arm1",
      duration: windUpDur,
      rotation: (-attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "arm2",
      duration: windUpDur,
      rotation: (attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "handle1",
      duration: windUpDur,
      rotation: (-attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "handle2",
      duration: windUpDur,
      rotation: (attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "blade1",
      duration: windUpDur,
      rotation: (-attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      fillStyle: bladeColor,
      delay: 0,
    },
    {
      name: "blade2",
      duration: windUpDur,
      rotation: (attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      fillStyle: bladeColor,
      delay: 0,
    },
    {
      name: "guard1",
      duration: windUpDur,
      rotation: (-attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "guard2",
      duration: windUpDur,
      rotation: (attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "ball1",
      duration: windUpDur,
      rotation: (-attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "ball2",
      duration: windUpDur,
      rotation: (-attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "ball3",
      duration: windUpDur,
      rotation: (attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "ball4",
      duration: windUpDur,
      rotation: (attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "blade1inside",
      duration: windUpDur,
      rotation: (-attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "blade2inside",
      duration: windUpDur,
      rotation: (attackWindUpRot * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    // Slash Animation
    {
      name: "arm1",
      duration: slashDur,
      rotation: (slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "arm2",
      duration: slashDur,
      rotation: (-slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "handle1",
      duration: slashDur,
      rotation: (slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "handle2",
      duration: slashDur,
      rotation: (-slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "blade1",
      duration: slashDur,
      rotation: (slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "blade2",
      duration: slashDur,
      rotation: (-slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "guard1",
      duration: slashDur,
      rotation: (slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "guard2",
      duration: slashDur,
      rotation: (-slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "ball1",
      duration: slashDur,
      rotation: (slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "ball2",
      duration: slashDur,
      rotation: (slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "ball3",
      duration: slashDur,
      rotation: (-slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "ball4",
      duration: slashDur,
      rotation: (-slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "blade1inside",
      duration: slashDur,
      rotation: (slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    {
      name: "blade2inside",
      duration: slashDur,
      rotation: (-slashRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: slashDelay,
    },
    //Blade Trail Animation
    {
      name: "bladeTrail",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      scale: 2.0,
      fillStyle: "#ffffffAA",
      delay: slashDelay + slashDur * 0.75,
    },
    {
      name: "bladeTrail",
      duration: 300,
      rotation: 0,
      x: 0,
      y: 0,
      fillStyle: "#ffffff00",
      delay: slashDelay + slashDur * 0.75 + 150,
    },
  ],
};


export default qAnimation;