//ANIMATION CONSTANTS
//////////////////////

const thrustDuration = 100;
const thrustDistance = 80;

const spearRotation = -20;
const bodyRotation = -60;

const remainDuration = 500;
const remainDelay = thrustDuration;

const returnDelay = remainDuration + remainDelay;
const returnDuration = 600;

const boarAttackAnimation = {
  animation: [
    /////////////////
    //WEAPON THRUST//
    /////////////////
    //Blade Trail Animation
    {
      name: "bladeTrail",
      duration: 0,
      rotation: 0,
      x: 0,
      y: -20,
      fillStyle: "#FEFFE8AA",
      delay: thrustDuration * 0+75,
    },
    {
      name: "bladeTrail",
      duration: 150,
      rotation: 0,
      x: 0,
      y: 100,
      fillStyle: "#FEFFE800",
      delay: thrustDuration * 0 + 125,
    },
    //Blade Trail End
    {
      name: "body",
      duration: thrustDuration,
      rotation: (bodyRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "Bhandle",
      duration: thrustDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: 0,
    },
    {
      name: "Bguard",
      duration: thrustDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: 0,
    },
    {
      name: "Bspear",
      duration: thrustDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: 0,
    },
    {
      name: "Bconnect",
      duration: thrustDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: 0,
    },
    ////PRONGS
    {
      name: "prong1",
      duration: thrustDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: 0,
    },
    {
      name: "prong2",
      duration: thrustDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: 0,
    },
    {
      name: "tip1",
      duration: thrustDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: 0,
    },
    {
      name: "tip2",
      duration: thrustDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: 0,
    },
    //////////
    //REMAIN//
    //////////
    {
      name: "body",
      duration: remainDuration,
      rotation: (bodyRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: remainDelay,
    },
    {
      name: "Bhandle",
      duration: remainDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: remainDelay,
    },
    {
      name: "Bguard",
      duration: remainDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: remainDelay,
    },
    {
      name: "Bspear",
      duration: remainDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: remainDelay,
    },
    {
      name: "Bconnect",
      duration: remainDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: remainDelay,
    },
    ////PRONGS
    {
      name: "prong1",
      duration: remainDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: remainDelay,
    },
    {
      name: "prong2",
      duration: remainDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: remainDelay,
    },
    {
      name: "tip1",
      duration: remainDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: remainDelay,
    },
    {
      name: "tip2",
      duration: remainDuration,
      rotation: (spearRotation * Math.PI) / 180,
      x: thrustDistance,
      y: 0,
      delay: remainDelay,
    },
    //////////
    //RETURN//
    //////////
    {
      name: "body",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
    {
      name: "Bhandle",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
    {
      name: "Bguard",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
    {
      name: "Bspear",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
    {
      name: "Bconnect",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
    ////PRONGS
    {
      name: "prong1",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
    {
      name: "prong2",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
    {
      name: "tip1",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
    {
      name: "tip2",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
  ],
};

export default boarAttackAnimation;
