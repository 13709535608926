////CASTING_R
//////1.
let flipDur = 500;
//////2.
let spinDelay = flipDur;
let spinDur = 6000;
let spinRot1 = -360*22;

//////3.
let unFlipDelay = spinDelay + spinDur
//////4.
let resetDelay = unFlipDelay + flipDur

let bladeColor = "#B9314F";

const ultimateAnimation = {
  animation: [
    /////////////////
    // Flip Animation
    /////////////////
    {
      name: "handle1",
      duration: flipDur,
      rotation: 0,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "blade1",
      duration: flipDur,
      rotation: 0,
      x: 0,
      y: 60,
      fillStyle: bladeColor,
      delay: 0,
    },
    {
      name: "blade2",
      duration: flipDur,
      rotation: 0,
      x: 0,
      y: 0,
      fillStyle: bladeColor,
      delay: 0,
    },
    {
      name: "blade2inside",
      duration: flipDur,
      rotation: 0,
      x: 0,
      y: 0,
      fillStyle: bladeColor,
      delay: 0,
    },
    {
      name: "guard1",
      duration: flipDur,
      rotation: 0,
      x: -27,
      y: 0,
      delay: 0,
    },
    {
      name: "ball1",
      duration: flipDur,
      rotation: 0,
      x: -27,
      y: 0,
      delay: 0,
    },
    {
      name: "ball2",
      duration: flipDur,
      rotation: 0,
      x: -27,
      y: 0,
      delay: 0,
    },
    {
      name: "blade1inside",
      duration: flipDur,
      rotation: 0,
      x: -87,
      y: 0,
      delay: 0,
    },
    ////////////////////
    // Spin Animation //
    ////////////////////
    {
      name: "arm1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "arm2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "handle1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "handle2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "blade1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 60,
      delay: spinDelay,
    },
    {
      name: "blade2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "guard1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: -27,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "guard2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "ball1",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: -27,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "ball2",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: -27,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "ball3",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "ball4",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "blade1inside",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: -87,
      y: 0,
      delay: spinDelay,
    },
    {
      name: "blade2inside",
      duration: spinDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: spinDelay,
    },
    //Un-FLIP
    /////////////////
    // Flip Animation
    /////////////////=
    {
      name: "blade1",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    {
      name: "guard1",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    {
      name: "ball1",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    {
      name: "ball2",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    {
      name: "blade1inside",
      duration: flipDur,
      rotation: (spinRot1 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: unFlipDelay,
    },
    ///HARD RESET

    {
      name: "arm1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "arm2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "handle1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "handle2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "blade1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "blade2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "guard1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "guard2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "ball1",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "ball2",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "ball3",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "ball4",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "blade1inside",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
    {
      name: "blade2inside",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      delay: resetDelay,
    },
  ],
};



// Blade Trail Animation Calculations
let fadeDur = 100;
let fadeDelay = 0;
let opaqueDelay = 100;
let opaqueAnimation = {
  name: "bladeTrail",
  duration: 0,
  rotation: 0,
  x: 0,
  y: 10,
  scale: 0.7,
  fillStyle: "#ffffffAA",
  delay: spinDelay,
};
let fadeAnimation = {
  name: "bladeTrail",
  duration: 150,
  rotation: 0,
  x: 0,
  y: 100,
  fillStyle: "#ffffff00",
  delay: spinDelay+150,
};
let lastKnownRot = 0 // Enforce a different position each time.
for (let i = 0; i<30; i++){
  let interval = opaqueDelay+fadeDelay+fadeDur;
  let opaqueAnimDelay = i*interval; 
  let fadeAnimDelay = fadeDelay + i*interval;

  let randomRot = lastKnownRot + (90+180*Math.random())*(Math.PI/180);
  lastKnownRot = randomRot;

  //BladeTrail1
  ultimateAnimation.animation.push({...opaqueAnimation, delay:spinDelay+opaqueAnimDelay, rotation: randomRot});
  ultimateAnimation.animation.push({...fadeAnimation, delay:spinDelay+fadeAnimDelay, rotation: randomRot});
}

export default ultimateAnimation

// bladeTrail1.Opaque      = 0         = 400       = 800        = 1200
// bladeTrail1.Transparent = 150->300  = 550->700  = 950->1100  = 1300->1450
// Opaque Equation = i* (fadeDur + fadeDelay + opaqueDelay);
// Transparent Equation = fadeDelay + i*(fadeDur + fadeDelay + opaqueDelay)