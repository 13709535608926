function interpolatePoint(x1, y1, x2, y2, proportion) {
  const x = x1 + proportion * (x2 - x1);
  const y = y1 + proportion * (y2 - y1);
  return { x, y };
}
const scale = 1.1;
///////////////////
//Shaft Constants//
///////////////////
const shaftHeight = 50;
const shaftWidth = 4;
const shaftX = (-shaftWidth / 2) ;
const shaftY = -shaftHeight - 2 - (18 / 2); //connectHeight:2, headHeight:18
const shaftColor = "#7E356F";
/////////////////////
//Connect Constants//
/////////////////////
const connectHeight = 2;
const connectWidth = shaftWidth;
const connectX = shaftX;
const connectY = shaftY + shaftHeight;
///////////////////////
//Arrowhead Constants//
///////////////////////
const headHeight = 18;
const headWidth = 8;
const headColor = "#7E356F";
////Point
const headX1 = connectX + connectWidth / 2;
const headY1 = connectY + connectHeight + headHeight;
////Right
const headX2 = headX1 - headWidth / 2;
const headY2 = headY1 - headHeight;
////Left
const headX3 = headX1 + headWidth / 2;
const headY3 = headY1 - headHeight;
//////////////////
//Line Constants//
//////////////////
const sinewColor = "#F0F9F7";
const start1 = {
  x: connectX + connectWidth,
  y: connectY,
};
const start2 = {
  x: connectX,
  y: connectY,
};
let end1 = interpolatePoint(headX2, headY2, headX1, headY1, 0.3);
let end2 = interpolatePoint(headX3, headY3, headX1, headY1, 0.3);
/////////////////////
//Feather Constants//
/////////////////////
let featherHeight = 20;
let featherWidth = 8;
let featherColor = "#FFFD82";
//Note to future self: Sorry bro, +1 to fix weird differences after scaling
let feather1 = {
  point1: {
    x: shaftX - shaftWidth / 2 + 1,
    y: shaftY,
  },
  point2: {
    x: shaftX - shaftWidth / 2 - featherWidth + 1,
    y: shaftY - 0.3 * featherHeight,
  },
  point3: {
    x: shaftX - shaftWidth / 2 + 1,
    y: shaftY + 0.7 * featherHeight,
    controlX: shaftX - shaftWidth - featherWidth / 2,
    controlY: shaftY + featherHeight / 2,
  },
};
let feather2 = {
  point1: {
    x: -shaftX + shaftWidth / 2 - 1,
    y: shaftY,
  },
  point2: {
    x: -shaftX + shaftWidth / 2 + featherWidth - 1,
    y: shaftY - 0.3 * featherHeight,
  },
  point3: {
    x: -shaftX + shaftWidth / 2 - 1,
    y: shaftY + 0.7 * featherHeight,
    controlX: -shaftX + shaftWidth + featherWidth / 2,
    controlY: shaftY + featherHeight / 2,
  },
};

const poisonArrowDrawInfo = {
  shapes: [
    {
      name: "shaft",
      type: "rectangle",
      x: shaftX * scale,
      y: shaftY * scale,
      width: shaftWidth * scale,
      height: shaftHeight * scale,
      fillStyle: shaftColor,
      shadowColor: headColor,
      shadowBlur: 10,
      lineWidth: 2,
      strokeStyle: "#000000",
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "connect",
      type: "rectangle",
      x: connectX * scale,
      y: connectY * scale,
      width: connectWidth * scale,
      height: connectHeight * scale,
      fillStyle: sinewColor,
      lineWidth: 2,
      strokeStyle: "#000000",
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "arrowhead",
      type: "polygon",
      points: [
        { x: headX1 * scale, y: headY1 * scale },
        { x: headX2 * scale, y: headY2 * scale },
        { x: headX3 * scale, y: headY3 * scale },
      ],
      xTrans: 0,
      yTrans: 0,
      fillStyle: headColor,
      shadowColor: headColor,
      shadowBlur: 10,
      lineWidth: 2,
      strokeStyle: "#000000",
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "line1",
      type: "line",
      lineWidth: 1,
      strokeStyle: sinewColor,
      x1: start1.x * scale,
      y1: start1.y * scale,
      x2: end1.x * scale,
      y2: end1.y * scale,
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "line2",
      type: "line",
      lineWidth: 1,
      strokeStyle: sinewColor,
      x1: start2.x * scale,
      y1: start2.y * scale,
      x2: end2.x * scale,
      y2: end2.y * scale,
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "feather1",
      type: "unique",
      fillStyle: featherColor,
      lineWidth: 2,
      strokeStyle: "#000000",
      x: feather1.point1.x * scale,
      y: feather1.point1.y * scale,
      rotation: (Math.PI * -90) / 180,
      points: [
        {
          type: "line",
          x: feather1.point2.x * scale,
          y: feather1.point2.y * scale,
        },
        {
          type: "quadratic",
          controlX: feather1.point3.controlX * scale,
          controlY: feather1.point3.controlY * scale,
          x: feather1.point3.x * scale,
          y: feather1.point3.y * scale,
        },
        {
          type: "line",
          x: feather1.point1.x * scale,
          y: feather1.point1.y * scale,
        },
      ],
    },

    {
      name: "feather2",
      type: "unique",
      fillStyle: featherColor,
      lineWidth: 2,
      strokeStyle: "#000000",
      x: feather2.point1.x * scale,
      y: feather2.point1.y * scale,
      rotation: (Math.PI * -90) / 180,
      points: [
        {
          type: "line",
          x: feather2.point2.x * scale,
          y: feather2.point2.y * scale,
        },
        {
          type: "quadratic",
          controlX: feather2.point3.controlX * scale,
          controlY: feather2.point3.controlY * scale,
          x: feather2.point3.x * scale,
          y: feather2.point3.y * scale,
        },
        {
          type: "line",
          x: feather2.point1.x * scale,
          y: feather2.point1.y * scale,
        },
      ],
    },
  ],
};

export default poisonArrowDrawInfo;
