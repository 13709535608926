import {
  AbilityConstants,
  DynamicInputs,
} from "../../../../constants/AbilitySystemConstants";

import qImage from "../../../../assets/champions/jirqo/abilities/q.png"
import eImage from "../../../../assets/champions/jirqo/abilities/e.png";
import rImage from "../../../../assets/champions/jirqo/abilities/r.png";




const jirqoAbilities = {
  [AbilityConstants.ATTACK]: {
    cooldown: 1000,
  },
  [AbilityConstants.Q]: {
    cooldown: 6000,
    name: "Poison Arrow",
    description: "Shoot a poison arrow",
    image: qImage,
  },
  [AbilityConstants.E]: {
    cooldown: 12000,
    dynamicInputs: [DynamicInputs.MOUSE_DIRECTION],
    name: "Adhesive Trap",
    description: "Fires an arrow that lands and creates a slowing zone.",
    image: eImage,
  },
  [AbilityConstants.R]: {
    cooldown: 20000,
    dynamicInputs: [DynamicInputs.MOUSE_DIRECTION],
    name: "Incendiary Rounds",
    description: "Shoots an arrow that detonates upon landing.",
    image: rImage,
  },
};
export default jirqoAbilities;
