//ANIMATION CONSTANTS
//////////////////////

const readyDuration = 300;

const dashDelay = readyDuration;
const dashDuration = 300; 

const returnDelay = dashDuration + dashDelay;
const returnDuration = 800;

const eAnimation = {
  animation: [
    /////////////////
    //WEAPON THRUST//
    /////////////////
    //Blade Trail Animation
    {
      name: "bodyTrail",
      duration: 0,
      rotation: 0,
      x: 0,
      y: 0,
      fillStyle: "#FEFFE899",
      delay: dashDuration * 0 + 75,
    },
    {
      name: "bodyTrail",
      duration: dashDuration+300,
      rotation: 0,
      x: 0,
      y: 40,
      fillStyle: "#FEFFE800",
      delay: dashDuration * 0 + 125,
    },
    {
      name: "shield",
      duration: readyDuration,
      rotation: (60 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
      fillStyle: "#272828",
    },
    {
      name: "shield",
      duration: dashDuration,
      rotation: (60 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: dashDelay,
    },
    {
      name: "shield",
      duration: returnDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: returnDelay,
    },
  ],
};

export default eAnimation;
