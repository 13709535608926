import biomeGrid from "./mapInfo";

class MapManager {
  constructor() {
    this.grid = biomeGrid;
  }
  getBiome(xBiome, yBiome) {
    return this.grid[yBiome][xBiome];
  }

  inBoundsBiome(xBiome, yBiome) {
    return (
      xBiome >= 0 &&
      yBiome >= 0 &&
      xBiome < this.grid[0].length &&
      yBiome < this.grid.length
    );
  }

  //Return the closest internal Biome given an out of bounds coordinate.
  closestInBoundsBiome(xBiome, yBiome) {
    while (xBiome < 0) {
      xBiome += 1;
    }
    while (xBiome >= this.grid[0].length) {
      xBiome -= 1;
    }
    while (yBiome < 0) {
      yBiome += 1;
    }
    while (yBiome >= this.grid.length) {
      yBiome -= 1;
    }
    return this.grid[yBiome][xBiome];
  }
}

const mapManager = new MapManager();

export default mapManager;