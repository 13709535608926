import shieldInfo from "../shield";
const scale = 5;
const bodyTrailScale = 10;

const drawInfo = {
  shapes: [
    {
      name: "body",
      type: "ellipse",
      radiusX: 40,
      radiusY: 56,
      selfRotation: 0,
      startAngle: 0,
      endAngle: 360,
      fillStyle: "#9aafab",
      lineWidth: 8,
      strokeStyle: "#000000",
      x: 0,
      y: 0,
      rotation: (Math.PI * 0) / 180,
    },
    ///////////
    ///HEAD////
    ///////////
    {
      name: "helmetmouth",
      type: "unique",
      fillStyle: "#41524e",
      lineWidth: 12,
      strokeStyle: "#111111",
      x: 41.6,
      y: 0,
      rotation: (Math.PI * -90) / 180,
      points: [
        { type: "quadratic", controlX: 36.8, controlY: 32.8, x: 0, y: 57.6 },
        { type: "quadratic", controlX: -36.8, controlY: 32.8, x: -41.6, y: 0 },
        { type: "line", x: 41.6, y: 0 },
      ],
    },
    //VISOR
    {
      name: "helmetvisor",
      type: "unique",
      fillStyle: "#090b0b",
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 38.4,
      y: -8,
      rotation: (Math.PI * -90) / 180,
      points: [
        { type: "quadratic", controlX: 32, controlY: 24, x: 0, y: 48 },
        { type: "quadratic", controlX: -32, controlY: 24, x: -38.4, y: -8 },
        { type: "line", x: 38.4, y: -8 },
      ],
    },

    //Lobe
    {
      name: "lobe",
      type: "unique",
      fillStyle: "#5c746f",
      lineWidth: 12,
      strokeStyle: "#111111",
      x: 41.6,
      y: 0,
      rotation: (Math.PI * -90) / 180,
      points: [
        { type: "quadratic", controlX: 40, controlY: -40, x: 0, y: -44.8 },
        { type: "quadratic", controlX: -40, controlY: -40, x: -41.6, y: 0 },
      ],
    },
    //TOP
    {
      name: "helmettop",
      type: "unique",
      fillStyle: "#5c746f",
      lineWidth: 4,
      strokeStyle: "#465854",
      x: 41.6,
      y: -8,
      rotation: (Math.PI * -90) / 180,
      points: [
        { type: "quadratic", controlX: 20, controlY: 20, x: 0, y: 32 },
        { type: "quadratic", controlX: -20, controlY: 20, x: -40, y: -8 },
        { type: "line", x: 41.6, y: -8.8 },
      ],
    },
    //LINES
    {
      name: "line1",
      type: "line",
      lineWidth: 1,
      strokeStyle: "#465854",
      x1: 0,
      y1: -44.8,
      x2: 0,
      y2: 32,
      rotation: (Math.PI * -90) / 180,
    },
    {
      name: "line2",
      type: "line",
      lineWidth: 1,
      strokeStyle: "#465854",
      x1: -41.6,
      y1: 0,
      x2: 41.6,
      y2: 0,
      rotation: (Math.PI * -90) / 180,
    },
    //////////////////
    //////SHIELD//////
    //////////////////
    ...shieldInfo,
    // Blade Trail
    {
      name: "bladeTrail",
      type: "unique",
      fillStyle: "#FEFFE800",
      lineWidth: 8,
      strokeStyle: "#FEFFE800",
      shadowColor: "#FEFFE8",
      shadowBlur: 4,
      x: 0,
      y: 0,
      xTrans: -30,
      yTrans: 120,
      rotation: (Math.PI * -110) / 180,
      points: [
        {
          type: "line",
          x: -20 * 0.75 * scale,
          y: -0 * scale,
        },
        {
          type: "line",
          x: -15 * 0.75 * scale,
          y: 15 * scale,
        },
        {
          type: "quadratic",
          x: -5 * 0.75 * scale,
          y: 15 * scale,
          controlX: -10 * 0.75 * scale,
          controlY: 35 * scale,
        },
        {
          type: "line",
          x: 0 * scale,
          y: 0 * scale,
        },
      ],
    },
    {
      name: "bodyTrail",
      type: "unique",
      fillStyle: "#FEFFE800",
      lineWidth: 8,
      strokeStyle: "#FEFFE800",
      shadowColor: "#FEFFE8",
      shadowBlur: 4,
      x: 0,
      y: 0,
      xTrans: 105,
      yTrans: -80,
      rotation: (Math.PI * -90) / 180,
      points: [
        {
          type: "line",
          x: -20 * 1.0 * bodyTrailScale,
          y: -0 * bodyTrailScale,
        },
        {
          type: "line",
          x: -15 * 1.0 * bodyTrailScale,
          y: 15 * bodyTrailScale,
        },
        {
          type: "quadratic",
          x: -5 * 1.0 * bodyTrailScale,
          y: 15 * bodyTrailScale,
          controlX: -10 * 1.0 * bodyTrailScale,
          controlY: 25 * bodyTrailScale,
        },
        {
          type: "line",
          x: 0 * bodyTrailScale,
          y: 0 * bodyTrailScale,
        },
      ],
    },
  ],
};

export default drawInfo;
