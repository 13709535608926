const glueArrowAnimation = {
  animation: [
    // Wind Up Animation
    {
      name: "glueCircle",
      duration: 500,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 300.0,
      delay: 1000,
    },
  ],
};

export default glueArrowAnimation;
